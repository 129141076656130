.mt10 {
  margin-top: 10px;
}

.fa-2 {
  font-size: 2em;
}

.allowed {
  color: #53e69d;
}

.disallowed {
  color: #ff7676;
}

.referral-token {
  font-size: 27px;
}

.white-box a {
  cursor: pointer;
}
.fc-day.fc-widget-content.fc-sun.fc-other-month.fc-past {
  border: 1px solid #ddd !important;
}

.css-bg1rzq-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #999;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
}

.techs-specialities input {
  width: 100% !important;
}

tr.existing td,
tr.existing label {
  color: #0a5d00 !important;
}

tr td .btn-group > .btn {
  height: 30px !important;
  padding: 4px !important;
  margin: 0 3px !important;
  text-align: center;
  border-radius: 4px !important;
  width: 30px !important;
}
tr.existing td .btn-group > .btn i {
  margin: 0;
  padding: 5px;
}
.justify-content-end {
  justify-content: flex-end;
}
.custom-loader {
  position: absolute;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.techs-specialities input {
  border: none !important
}
