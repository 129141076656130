

.add-new-item {
    position: absolute;
    background: #fff;
    z-index: 9999999999;
    width: 50%;
    left: 0;
    height: 100%;
    top: 0;
    padding: 20px 30px;
    box-shadow: 0 0 3px;
    transform: translateX(-100%);
    transition: 400ms linear
}

.add-new-item.visible {
  transform: translateX(0);
}
.manage-item {
  margin: 5px 0;
  transition: 400ms linear;
  padding: 0 3px;
  cursor: pointer;
  position: relative
}
.manage-item label{
  margin-bottom: 0;
  cursor: pointer
}
.manage-item:hover{
  background: #f2f2f2
}

.manage-component {
    height: 400px;
    overflow: hidden;
    overflow: scroll;
    margin-top: 10px;
}

.updatable-item {
    position: absolute;
    top: 0;
    overflow: hidden;
    left: 0;
  width: 100%;
    transform: translateX(-100%);
    transition: 400ms linear;
    opacity: 0;
  visibility: hidden
}

.updatable-item.active{
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}
