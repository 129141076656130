
.home-page .white-box{
    background: none
}


.mb20{
    margin-bottom:20px
}

@media screen and (max-width: 480px){
   
}

.home-logos {
	width: 100%;
	height: 200px;
	object-fit: cover;
}
#home-page-wrapper h1{
    padding: 0px !important
}