/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.referral-token {
    font-size: 27px;
}

.pb35 {
    padding-bottom: 35px;
}

.mt0 {
    margin-top: 0px !important
}

.mt10 {
    margin-top: 10px !important
}

#addAppointment .modal-body {
    height: 500px;
}

.inline {
    display: inline !important;
}

.fa-5 {
    font-size: 2.9em;
}

.mb0 {
    margin-bottom: 0
}

#createEvent .modal-body {
    height: 600px;
}

.fc-event {
    cursor: pointer;
}

.cd-horizontal-timeline .steamline a {
    color: white
}

.cd-horizontal-timeline {
    margin-top: 0
}

.cd-horizontal-timeline .events-content {
    margin-top: 0
}

.visitSummaryPanel {
    background: #edf1f5;
}

.customtab li {
    position: relative !important;
    z-index: unset !important;
    width: initial !important;
    left: 0 !important;
    top: 0 !important;
    -webkit-transform: unset !important;
    -ms-transform: unset !important;
    transform: unset !important;
    padding: inherit !important;
    opacity: 100 !important;
    -webkit-animation-duration: unset !important;
    animation-duration: unset !important;
    -webkit-animation-timing-function: unset !important;
    animation-timing-function: unset !important;
}

.visitHistoryPanelBody {
    max-height: 170px;
    overflow-y: scroll;
}

.spanLink {
    cursor: pointer;
    color: blue;
}


.divider {
    border: 3px dotted #999;
    background: #999
}

.margin-top-0 {
    margin-top: 0 !important
}


.rounded {
    border: 1px solid #ddd;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 12px;
    background: #FAFAFA;
}

.rounded.head-part p {
    border-bottom: 1px solid #ddd;
    margin-bottom: 3px;
}

.rounded.head-part p:last-child {
    border-bottom: none
}

select {
    border: 2px solid #999 !important;
}

.selection-part .form-group {
    position: relative
}

.selection-part .form-group::before {
    position: absolute;
    right: 0px;
    width: 30px;
    height: 30px;
    background: #fff;
    content: "\25BC";
    top: 33px;
    height: 41.2px;
    pointer-events: none;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 10px;
    color: #999;
    cursor: pointer;
    z-index: 9999;
    border-radius: 0 5px 5px
}

.selection-part input {
    border-radius: 5px 0 0 5px !important
}

.summary-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.summar-part .panel.panel-default {
    border: 1px solid #ddd;
    margin-top: 19px;
    text-align: center;
    border-radius: 5px;

}

.summar-part .panel-default .panel-heading, .summar-part .panel-white .panel-heading {
    background: #f5f5f5
}

.summary-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.summary-list button {
    background: none;
    border: none
}

.service-increment {
    margin-top: 20px;
    cursor: pointer;
}

.service-increment .panel-heading {
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden
}

.panel-wrapper {
    border: 1px solid #ddd
}

.panel-wrapper .panel-heading.panel-wrapper-heading {
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    position: relative;
    padding: 17px 0 !important;
}

.panel-wrapper .panel-heading.panel-wrapper-heading:before {
    content: '\f0c9';
    position: absolute;
    right: 10px;
    top: 24%;
    font-family: 'FontAwesome';
}


.service-increment .panel-heading {
    overflow: hidden;
    background: #707cd2;
    color: #fff;
}

.customer-lookup.add-form input[type="search"] {
    margin-bottom: 5px
}

.customer-lookup.add-form input {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 24px !important;
}

.customer-lookup button {
    position: absolute;
    top: 5px;
    right: 0;
    background: none;
    border: none;
    font-size: 18px;
}

.customer-lookup button i {
    color: #ccc
}

.customer-lookup .btn {
    margin-left: 38px;
    margin-top: 0
}

.customer-lookup.add-form select {
    border-radius: 0px !important
}

.customer-lookup.add-form .select-arrow::before {
    height: 37.2px;
    font-size: 12px;
    top: 33px !important;
}

.add-form .select-arrow::before {
    top: 33px !important;
}


/* new customer modal */

#newCustomerModal input {
    padding-left: 38px
}

#newCustomerModal .form-group {
    position: relative
}

#newCustomerModal .form-group::before {
    position: absolute;
    left: 8px;
    top: 35px;
    width: 25px;
    height: 25px;
    background: #fff;
    font-family: FontAwesome;
    text-align: center;
    border-radius: 50%;
    color: #2CABE3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-group.fname:before {
    content: '\f007'
}

.form-group.email:before {
    content: '\f0e0'
}

.form-group.mobile:before {
    content: '\f095'
}

.form-group.password:before {
    content: '\f023'
}

.form-group.dfb:before {
    content: '\f073'
}

.form-group.referal:before {
    content: '\f074'
}


/* time off */

.custom-modal {
    width: 480px
}

#requestTimeOff .custom-modal .modal-body {
    height: 345px;
}

.custom-modal .modal-body label {
    display: block;
    font-size: 17px;
    color: #777 !important;
    font-weight: 300;
    margin-bottom: -2px;
}

.custom-modal .modal-body span {
    color: #000 !important;
    font-size: 16px;
    margin-bottom: 10px !important;
    display: block;
}

.custom-modal .modal-body span:last-child {
    margin-bottom: 15px !important;
}

.custom-modal .modal-body input {
    border-radius: 0;
}

.custom-modal .modal-body .group-input label {
    font-size: 15px;
}

#addServices.modal.in {
    display: block;
}

#addProducts.modal.in {
    display: block;
}

#serviceCheckout.modal.in, #MiniModal.modal.in {
    display: block;
}

#serviceCheckout.modal, #MiniModal.modal {
    display: none;
}

div#serviceCheckout .modal-body {
    height: 255px;
}

#MiniModal .modal-body, #miniWait .modal-body {
    padding: 25px;

}

#MiniModal .modal-body ul {
}

#closeBook.modal {
    z-index: 9999999999 !important
}

.envelop {
    position: absolute;
    top: -11px;
    width: 20px;
    height: 20px;
    background: red;
    text-align: center;
    border-radius: 10px;
    right: -8px;
    z-index: 9999;
}

.envelop i {
    margin-right: 0 !important;
    margin-left: 3px;
    margin-top: 4px;
}

.fc-time-grid-event.fc-event.fc-start.fc-end {
    overflow: visible !important;
}

.scroll {
    width: 144px;
    height: 120px;
    left: -2px;
    position: absolute;
    z-index: 99999;
    text-align: left;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    display: none;
}

.scroll h6 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 10px;
}

.fc-time-grid-event.fc-event.fc-start.fc-end .envelop:hover + .scroll {
    display: block;
}

.active-on-hover {
    position: absolute;
    top: 0;
    left: 100%;
    width: 200px;
    height: 200px;
    z-index: 9999999999;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    padding: 0 10px
}

.fc-content:hover + .active-on-hover {
    opacity: 1;
    visibility: visible;
}

img.resource-img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: auto;
    display: block;
}

th.fc-resource-cell.rubel div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


.fc-time-grid .fc-event-container {
    z-index: inherit !important;
}

.fc-time-grid-event.fc-event.fc-start.fc-end {
    z-index: initial !important;
}

.scroll:hover {
    display: block;
}

.scroll-wrap {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-right: 18px;
}

.reset-btn {
    position: absolute;
    top: -22px;
    left: 0;
    width: 100%;
}

.reset-btn button {
    background: none;
    height: auto;
    border: none !important;
    width: 100%;
    padding: 0;
}

th.fc-resource-cell.rubel {
    -webkit-perspective: 20px;
    perspective: 20px;
}

th.fc-resource-cell.rubel:hover .reset-btn {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

.reset-btn {
    position: absolute;
    top: -19px;
    left: 0;
    width: 100%;
    -webkit-transform: rotateX(45deg);
    transform: rotateX(45deg);
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
}

.rc-time-picker-panel-select:nth-of-type(2) li {
    display: none;
}

.rc-time-picker-panel-select:nth-of-type(2) ul li:nth-child(5n+1) {
    display: block !important;
}

.fc-highlight {
    opacity: 1 !important;
}

.fc-icon.fc-icon-fa.fa-calendar:before {
    content: "\f073" !important;
    font-family: 'FontAwesome'

}


button.fc-button.fc-button-primary {
    background: #fff !important;
    color: #000;
}


.fc-toolbar.fc-header-toolbar button {
    border: 1px solid #000 !important;
    color: #000 !important;
    text-transform: uppercase;
    margin: 0 !important
}

.fc-toolbar.fc-header-toolbar .fc-icon-chevron-left:before {
    content: "\f0d9" !important;
    font-family: 'FontAwesome';
    font-size: 20px


}

.fc-toolbar.fc-header-toolbar .fc-icon-chevron-right:before {
    content: "\f0da" !important;
    font-family: 'FontAwesome';
    font-size: 20px


}

.custom-calendar-picker-component {
    position: absolute;
    z-index: 9999999;
}


@media only screen and (min-width: 0) and (max-wdith: 480px) {

    .custom-calendar-picker-component {
        left: 10px !important
    }

}

@media only screen and (min-width: 480px) and (max-wdith: 760px) {

    .custom-calendar-picker-component {
        left: 10px !important;
        background: red
    }
}

@media only screen and (min-width: 760px) and (max-wdith: 960px) {

}

@media only screen and (min-width: 960px) {
    .rmdp-day, .rmdp-week-day {
        height: 50px !important;
        width: 50px !important;

    }

    .rmdp-day span {
        font-size: 16px !important;
        font-weight: 700;
        color: #666
    }
}

.rmdp-week-day {
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
}

.rmdp-day, .rmdp-week-day {

    border: 1px solid #ddd !important;
}

.custom-calendar-picker-component .close-btn {
    left: 47%;
    top: -5px;
    right: auto;
}
