
.mt10 {
    margin-top: 10px
}

.fa-2 {
    font-size: 2em;
}

.allowed {
    color: #53e69d
}

.disallowed {
    color: #ff7676
}

.referral-token {
    font-size: 27px;
    padding-left: 7px;
}

.white-box a {
    cursor: pointer;
}

/* customized by Rubel Ahmmed */
.user-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

fieldset {
    padding: 10px 25px;
}

.customer-details-tab input[type="text"], .customer-details-tab input[type="number"], .customer-details-tab select, .customer-details-tab input[type="time"], .customer-details-tab input[type="date"], .customer-details-tab input[type="email"], .customer-details-tab input[type="password"], .customer-details-tab textarea {
    width: 100%;
    padding: 11px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    color: black;
    border-radius: 0;
}

.customer-details-tab label {
    color: #666;
    font-size: 11px;
}

legend {
    margin-bottom: 0px;
}

.divide-br {
    width: 50%;
    height: 1px;
    background: #999;
    margin: 25px auto;
}

form {
    padding-top: 15px;
}

.customer-details-tab .btn.btn-success {
    border-radius: 0;
    text-transform: uppercase;
    padding: 12px 15px;
    margin-top: 0
}

#rewards th {
    font-size: 16px;
    font-weight: 600;
}

#rewards td {
    font-size: 15px;
    font-weight: 600;
}

.pending-form li {
    display: flex;
    align-items: center;

}


.pending-form li span {
    width: 300px
}

.completed li {
    margin: 15px 0
}

.my-custom-scrollbar {
    position: relative;
    height: 300px;
    overflow: auto;
}

.table-wrapper-scroll-y {
    display: block;
}

#forms a:hover, #forms a:focus {
    background: none
}

.consent iframe {
    width: 100%;
    height: 100vh
}

.consent iframe {
    border: 1px solid #ddd;
}

.consent .modal-body {
    height: 100vh !important;
}

.consent .modal-dialog {
    width: 90%;
}

.consent .modal-body .wrapper {
    width: 80%;
    margin: 40px auto;
    padding: 10px;
    box-sizing: border-box;
}


.sign {
    width: 322px !important;
}

.consent .modal-body .consent-wrapper.wrapper p {
    font-size: 16px
}

.consent .modal-body .consent-wrapper.wrapper {
    width: 100%;
    margin: 40px auto;
    padding: 10px;
    box-sizing: border-box;

}

.text-body {
    text-align: justify;
}

.form-footer {
    margin-top: 35px;
}

.consent-wrapper input {
    border: none;
    border-bottom: 2px solid #444;
    font-weight: 600;
    border-radius: 0
}

.consent-wrapper label {
    font-size: 16px;
    width: 25%;
    display: inline-block;
}

.consent-wrapper .form-group {
    margin-bottom: 30px;
}

.consent-wrapper .form-group input {
    width: 74%;
}

.consent-wrapper .logo {
    width: 170px;
    margin: auto;
    margin-bottom: 30px;

}

.consent-wrapper .logo img {
    width: 100%;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .consent .modal-body .consent-wrapper.wrapper {
        width: 100%;

    }

    .consent-wrapper label {
        width: 60%;
    }

    .consent-wrapper .form-group input {
        width: 38%;
    }

    .consent-wrapper .text-body input {
        width: 100%;
    }
}


@media only screen and (min-width: 480px) and (max-width: 960px) {
    .consent .modal-body .consent-wrapper.wrapper {
        width: 100%;
    }

    .consent-wrapper label {
        width: 50%;
    }

    .consent-wrapper .form-group input {
        width: 48%;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1200px) {
    .consent .modal-body .consent-wrapper.wrapper {
        width: 75%;
    }
}

.present-appointments .add-form .select-arrow::before {

    top: 32px !important;
    border-radius: 0 !important
}

.present-appointments .add-form .form-control {
    margin-bottom: 0;
    border-radius: 0 !important
}

.present-appointments .btn.btn-success {
    padding: 9px 22px !important;
}

#appointment .tab-content {
    margin-top: 0px;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 40px 0px
}

.inner-tab .nav-tabs li:last-child {
    margin-left: 0px;
}


/* customized by rubel */
.form-group, .form-horizontal .form-group {
    margin-bottom: 10px;
}

.input-group-addon {
    border-radius: 0;
    border: 1px solid #999;
}

.input-group-addon {
    background-color: #fff;
}


.customer-lookup.add-form select {
    border-radius: 0px !important;
}

.add-form .select-arrow::before {
    content: none !important
}


.expense .list-group li {
    position: inherit;
    opacity: 1;
    transform: translateX(0)
}

.events-content .add-form .form-control {
    margin-bottom: 0 !important
}

.events-content .bottom-info .note {
    padding: 1px 5px;
}

.cd-horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 48px;
    height: 2px;
    background: #dfdfdf;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}

.cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 1.3rem;
    padding-bottom: 26px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.cd-timeline-navigation a {
    position: absolute;
    z-index: 1;
    top: 67%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    transition: border-color 0.3s;
}

.events a::before {
    position: absolute;
    left: 0;
    top: 18px;
    width: 100%;
    content: attr(data-text);
    color: red;
    font-size: 11px;
}


.consent-wrapper input {
    border: none !important;
    border-bottom: 2px solid #999 !important;
}

select {
    height: 42px !important;
}

.save-image-btn {
    text-align: center;
}

.save-image-btn .btn {
    width: 109px;
    height: 50px;
}

canvas#canvas2 {
    width: 0;
}

canvas#canvas1 {
    width: 0;
}

#viewForm .modal-body {
    height: auto;
}

#viewForm .modal-body img {
    width: 100%;
}

.user-btm-box p {
    word-break: break-word;
}

.settings .filter-control.true {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}
