#to-recover{
    padding-top: 10px;
    cursor: pointer;
}

#to-login{
    cursor: pointer;
}

.mt10{
    margin-top:10px
}

@media only screen and (max-width: 960px){
    .login-box.login-sidebar {
      position: relative;
      background: none;
      background: none;
}

form {
    padding-top: 0;
}
.white-box {
    background: rgba(255,255,255,.6);
    border: 1px solid rgba(255,255,255,.5);
}}