/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/


.caller-wraper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 99999999999;
    padding: 30px 100px;
    -webkit-transition: .5s linear;
    -o-transition: .5s linear;
    transition: .5s linear;

}

body.calling .caller-wraper {
    -webkit-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    transform: translateX(-200px);

}

.caller-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    width: calc(100% - 150px);
}

.name-status h3, .name-status span {
    color: #fff
}

.menu {
    width: 50px;
    height: 50px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
    -webkit-transition: .4s linear;
    -o-transition: .4s linear;
    transition: .4s linear;
    position: relative;

}

.menu.active {
    -webkit-transform: translateX(-352px);
    -ms-transform: translateX(-352px);
    transform: translateX(-352px);

}


.menu.active span {
    background: transparent;
}

.menu.active span:before {
    -webkit-transform: rotate(32deg);
    -ms-transform: rotate(32deg);
    transform: rotate(32deg);
    -webkit-transform-origin: 4%;
    -ms-transform-origin: 4%;
    transform-origin: 4%;
}

.menu.active span:after {
    -webkit-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
    transform: rotate(-35deg);
    -webkit-transform-origin: 28%;
    -ms-transform-origin: 28%;
    transform-origin: 28%;
}

.menu span {
    width: 30px;
    height: 2px;
    background: red;
    display: block;
    position: relative;
}

.menu span:before {
    width: 30px;
    height: 2px;
    background: red;
    position: absolute;
    top: -6px;
    content: '';
}

.menu span:after {
    width: 30px;
    height: 2px;
    background: red;
    position: absolute;
    top: 6px;
    content: '';
}

.hidden-context {
    position: fixed;
    right: 0;
    width: 400px;
    height: 100%;
    background: #fff;
    top: 0;
    -webkit-transform: translateX(400px);
    -ms-transform: translateX(400px);
    transform: translateX(400px);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    z-index: 999999;
}

body.calling .hidden-context {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}

.hidden-content-inner {
    padding: 30px;
}

.caller {
    margin-top: 350px;
}

.pics {
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    -ms-flex-item-align: center;
    align-self: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 100px auto;

}

.controller {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    left: 0;
    width: 200px;
    margin: auto;
    cursor: pointer;
}

.icon-calling svg {
    width: 50px
}

.mic {
    width: 50px;
    height: 50px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
}

.call {
    width: 50px;
    height: 50px;
    background: #f12727e6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
}

.caller {
    position: fixed;
    width: 100%;
}

.status-text {
    margin-right: 5px;
}

.loadingg span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: red;
    border-radius: 50%;
    margin: 0 3px;
    -webkit-animation-name: dots;
    animation-name: dots;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}


.loadingg span:nth-child(2) {
    background: blue;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
}

.loadingg span:nth-child(3) {
    background: goldenrod;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}

.status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.caller-popup {
    position: fixed;
    width: 400px;
    height: 600px;
    background: red;
    z-index: 9999;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -300px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 2px #ddd;
    box-shadow: 0 0 2px #ddd;
}

.popup-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 378px;
    background: #fff;
    border-radius: 48%;
    margin-top: -200px;
    width: 584px;
    margin-left: -107px;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.profile-pick {
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-top: 173px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.profile_ {
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
}

.caller-popup {
    position: fixed;
    width: 400px;
    height: 600px;
    background: #1a76a4;
    z-index: 99999999999999999 !important;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -300px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 2px #999;
    box-shadow: 0 0 2px #999;
}

.intro {
    text-align: center;
    width: 200px;
    margin: auto;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.calling {
    position: absolute;
    bottom: 0;
    z-index: 99999999;
    width: 50px;
    height: 50px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    bottom: -24px;
    border: 2px solid #0e587d;
    cursor: pointer;

}

.calling.active {
    border: 2px solid green;
    -webkit-animation-name: ringing;
    animation-name: ringing;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

}

.book {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 30px;
}

.caller-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    z-index: 99999999999999;
}

.action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.book {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 30px;
    height: 30px;
    width: 50px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.content {
    padding: 50px;
    width: 100%;
}

.content-single {
    border-bottom: 1px solid #0e587d;
    border-top: 1px solid #288dbf;
    padding: 3px 0;
}

.content-single span {
    color: #fff;
}

.content-single:nth-of-type(1) {
    border-top: 0;
}

.content-single:nth-of-type(5) {
    border-bottom: 0;
}

.icon-part span {
    color: goldenrod;
    font-weight: normal;
}

.inn {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-right: 40px;
    padding-bottom: 80px;
}

.tech {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.appt-content p {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    line-height: 17px;
    color: #fff
}

.hiden-part {
    overflow: hidden;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.hiden-part h5 {
    color: #fff
}

.appt-content p span {
    font-weight: 700;
}

.icon-part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

span.toggler {
    text-align: right;
    cursor: pointer;
    font-size: 25px;

}

span.toggler i {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}


.numbers {
    margin-bottom: 38px;
    margin-top: -33px;
}

.numbers input[type="text"] {
    border: 0 !important;
    background: none;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}

.key-pad {
    position: absolute;
    left: 0;
    bottom: 70px;
    right: 0;
    height: 300px;
    background: #1a76a4;
    padding: 20px 0;
    -webkit-transform: translateY(300px);
    -ms-transform: translateY(300px);
    transform: translateY(300px);
    -webkit-transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    -o-transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
}

.keypad-inner button {
    background: none;
    border: none;
    color: #fff;
    font-size: 15px;
    width: 100%;
    text-align: center;
    padding: 15px 10px;
    font-size: 20px;
}

.keypad-inner .row {
    border-bottom: 1px solid #0e587d;
    border-top: 1px solid #288dbf;
}

.keypad-inner .row:nth-of-type(1) {
    border-top: 0;
}

.keypad-inner .row:nth-of-type(4) {
    border-bottom: 0;
}

.keypad-inner .row .col-md-4 {
    border-right: 1px solid #0e587d;
    border-left: 1px solid #288dbf;
}

.caller-popup .content {
    height: 377px;
    overflow: hidden;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding-left: 20px;
    padding-top: 60px;
    padding-bottom: 200px;
    -webkit-transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    -o-transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    background: #1a76a4;
}


@-webkit-keyframes ringing {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}


@keyframes ringing {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}


i.icon-phone {
    font-size: 30px;
    text-align: center;
    padding-left: 8px;
}

.active i.icon-phone {
    color: green;
}

.close-btn {
    position: absolute;
    z-index: 999;
    right: 0;
    padding: 10px;
    cursor: pointer;
}

.close-btn i {
    color: red
}


#pop {
    display: none;
}

.profile-pick {
    overflow: hidden;
}

.profile-pick img {
    width: 50px;
    height: 50px;
    overflow: hidden;
}

.appt-content p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
}

.appt-content span {
    colorL: '#fff'
}

.contents {
    height: 377px;
    overflow: hidden;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding-left: 20px;
    padding-top: 60px;
    padding-bottom: 200px;
    -webkit-transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    -o-transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    transition: .5s cubic-bezier(0, 0.85, 0.79, 0.39);
    background: #1a76a4;
}

#calling {
    display: none;
}

div#callingBtn {
    margin-left: -53px;
    display: none;
}

.icon-filter {
    width: 35px;
    height: 35px;
    background: red;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 21px;
    color: #fff;
    border-radius: 50%;
}

.icon-filter i {
    margin-right: 0;
    padding-right: 0;
}

.label-filter {
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
}

.filter-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.filter {
    position: relative;
}

.filter-control {
    width: 221px;
    padding: 10px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    position: absolute;
    top: 50px;
    border: 1px solid #ddd;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    visibility: hidden;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: .4s linear;
    -o-transition: .4s linear;
    transition: .4s linear;
    transform-origin: top left;
    background: #fff;
    z-index: 999;
}

.filter-control.visible {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.filter-control:before {
    position: absolute;
    top: -9px;
    width: 17px;
    height: 17px;
    content: '';
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 7px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    z-index: 99999999;
    background: #fff;

}

.go {
    margin-top: 20px;
    cursor: pointer;
}

.label-part {

    margin-top: 20px;
}

.lbls {
    border-bottom: 1px solid #ddd;
}

.lbls h5 {
    font-weight: 600;

}

.rw-content img {
    width: 25px;
    height: 25px;
}

.row.rw-content {
    font-weight: 500;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

div#error-show {
    margin-top: 24px;
    text-align: center;
    margin-left: -26px;
    color: red;
    display: none
}

.filter-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chat-app {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 50px);
    z-index: 999999999999;
    background: #fff;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: .4s linear;
    -o-transition: .4s linear;
    transition: .4s linear;
}

.chat-app.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.chat-app .intro {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 5px 10px;
    height: 45px;
}

.chat-app .profile-pick {
    margin-top: 0;
}

.chat-app .profile-pick img {
    width: 40px;
    height: 40px;
    overflow: hidden;
}

div#sms-sender {
    height: 50px;
    border-top: 1px dashed #996;
}

div#sms-sender textarea {
    height: 50px;
    border: 0 !important;
    padding-right: 43px;
    color: #666;
}

div.sms {
    height: calc(100% - 96px);
}


::-webkit-input-placeholder { /* Edge */
    color: #999;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999;
}

::-moz-placeholder {
    color: #999;
}

::-ms-input-placeholder {
    color: #999;
}

::placeholder {
    color: #999;
}

#sms-sender {
    position: relative;
}

.sender-icon {
    position: absolute;
    z-index: 99999999999999999999;
    right: 6px;
    top: 7px;
    cursor: pointer;
}

.sender-icon i {
    font-size: 25px;
    color: #999;
    -webkit-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;

}

.sender-icon:hover i {
    color: green;
}

.scroller {
    width: 100%;
    overflow: hidden;
}

div.sms {
    height: calc(100% - 96px);
    overflow: scroll;
    padding: 20px;
}

.client-image {
    margin-right: 15px;
}

.client-image img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.client-content-inner p {
    font-size: 13px;
    color: #666;
    margin-bottom: 5px;
}

.client-content-inner {
    background: #f2f2f2;
    padding: 5px;
    border-radius: 0px 10px 0px 10px;
    position: relative;
    margin-top: 3px;
}

.client-content-inner:before {
    position: absolute;
    content: '';
    left: -10px;
    top: 0;
    width: 0;
    height: 0;
    border-right: 10px solid #f2f2f2;
    border-bottom: 10px solid transparent;
}

.support .client-image {
    margin-right: 0;
    margin-left: 15px;
}

.support .client-content-inner {
    border-radius: 10px 0px 10px 0px;
    margin-top: 3px;
}

.support .client-content-inner:after {
    position: absolute;
    content: '';
    right: -10px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 10px solid #f2f2f2;
    border-bottom: 10px solid transparent;
}

.message-center.message-body {
    padding: 17px 10px;
}

.message-center .user-img {
    width: 40px;
    float: left;
    position: relative;
    margin: 0 16px 15px 0;
    height: 40px;
    border-radius: 50%;
}

.message-center .user-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.message-center .mail-contnet .mail-desc {
    margin: 0;
    margin-right: 50px;
}

.message-center .message-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.message-center .message-item .user-img {
    margin-bottom: 0;
}

span.mail-contnet > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

}

.dropdown-menu .message-center .mail-contnet .time {
    display: block;
    font-size: 10px;
    color: #313131;

}

.dropdown-menu .message-center.message-body {
    padding: 4px 10px;
}

.dropdown-menu .message-center .user-img .profile-status {
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: 21px;
    left: 30px;
    position: absolute;
    top: 1px;
    width: 21px;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    color: #000;
}


.error-handling {
    text-align: center;
    margin-top: 25px;
    position: absolute;
    width: 100%;
}

.call-duration {
    position: absolute;
    top: 23px;
    left: 6px;
    z-index: 999999;
    font-weight: 600;
}

.client.support {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.client.support .client-content-inner:before {
    content: none
}

.scroller {
    width: 100%;
    overflow: hidden;
}

.audio {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    width: 0;
    height: 0;
}

#ms-balance .close-btn {
    position: inherit;
    padding: 0;
}

.load-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 40px;
    text-align: center;
    border-top: 4px solid #f2f2f2;
}

.load-more-button {
    margin-top: 20px;
}

.load-more-button i {
    padding-right: 0;
    font-size: 30px;

}

.load-more-button button {
    border: 0;
    background: none !important;
}

.load-more-button.loadMore i {
    -webkit-animation-name: fa-spin;
    animation-name: fa-spin;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
}

.techs-specialities svg {
    width: 15px;
}

.col-md-6.techs-specialities > div > div > div {
    border: 2px solid #999;
    border-radius: 0;
}

.col-md-6.techs-specialities > div > div > div > div {
    padding: 0 8px
}

#add-tech .settings {
    padding: 0
}

.active-context {
    background: #f2f2f2;
    -webkit-transition: .5s cubic-bezier(0, 1.1, 0.9, 0.35);
    -o-transition: .5s cubic-bezier(0, 1.1, 0.9, 0.35);
    transition: .5s cubic-bezier(0, 1.1, 0.9, 0.35);
}

#waitlists .steamline {
    border-left: 0 !important;
}

#waitlist .steamline {
    border-left: 0;
}

#appointment .steamline {
    border-left: 0;
}


input[type=search] {
    color: #999 !important;
}


.top-left-part {
    border-right: none;
    width: 170px;
    margin-top: 1px;
}

.dw-user-box a {
    color: white;
}

a.waves-effect.waves-light.com {
    margin-top: 18px;
}

a.waves-effect.waves-light.com svg {
    width: 25px;
}

.perspective {
    -webkit-perspective: 84rem;
    perspective: 84rem;
    z-index: 9999999999;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
}

.communication {
    height: 100vh;
    position: absolute;
    right: 0;
    background: #ddd;
    z-index: 999999;
    top: 0;
    position: fixed;
    overflow: hidden;
    -webkit-transition: 500ms linear;
    -o-transition: 500ms linear;
    -webkit-transform: rotateY(-136deg);
    transform: rotateY(-136deg);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    z-index: 9999999999;
    width: 400px;
}

#activate {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.single-com {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    padding: 10px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #999;
}

.communication .scroller {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding: 10px;
}

.com-action {
    width: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

}

.filter {
    position: relative;
    width: 30px;
    text-align: center;
}

.user-image {
    width: 30px;
}

.name-phone-message {
    width: calc(100% - 130px);
    margin-left: 10px;
}

.name-phone-message p {
    font-size: 11px;
    margin: 0;
}

.single-com button {
    width: 38px;
    padding: 0 3px;
    border: none;
    background: none;
}

.single-com button svg {
    width: 100%;
}

.single-com:nth-of-type(1) {
    border-top: 0;
}

.communication .scroller .single-com:first-child {
    border-top: none;
}

.communication .scroller .single-com:last-child {
    border-bottom: none;
}

.communication .scroll-inner {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.section-title {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-right: 20px;
    padding-top: 10px;
    margin-bottom: 10px;
}

.section-title button {
    border: none;
    background: none;
    color: red;
}

.no-records {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
}

.paged {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 7px;
    margin-right: 37px;
    border-top: 4px solid #f2f2f2;
    margin-top: 11px;
    padding-top: 10px;
    padding-bottom: 50px;
    cursor: pointer;
}

.filteration {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.filteration h2 {
    margin-bottom: 0;
}

.filteration h5 {
    margin-top: 0;
}

.com-action i {
    font-size: 18px;
    color: #666;
}

#mail .modal-body {
    height: auto !important;
}

#mail .modal-body table {
    width: 100% !important;
}

#mail .modal-custom {
    width: 450px;
}

.types {
    text-align: left;
}

.control-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.form-check {
    margin-right: 10px;
}

div#sms-sender.errors {
    border: 2px solid red;
}
