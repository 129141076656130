.panel-heading{
    padding:8px !important;
}

.p0{
    padding:0;
}

.mh199{
    min-height:199px
}

.panel-footer{
   padding:7px 17px
}

.mt35{
    margin-top:35px;
}

.p5{
    padding:5px;
}

.status-text{
    font-weight:700;
    color:#666
}

.pager{
    cursor: pointer;
}

.hr-border {
    left:0% !important;
}

.pull-left span{
    color: rgb(102, 102, 102);
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
}

.pr0{
    padding-right:0
}

.bl1{
    border-left: 1px solid #b2bcc5;
    padding-left: 5px;
}

.customer-panel-footer{
    text-align: right;
    padding-bottom: 0px!important;
}

.ml10{
    margin-left:10px
}


.event-overlay:before{
    position: absolute;
    content: " ";
    top: 0;
    left: 15px;
    width: 93.2%;
    height: 89%;
    display: block;
    z-index: 0;
    background-color: white;
    opacity: 0.9;
}

.birthday .event-overlay:before{
    background-color: #fdc9a0;
}

.sweepstakes .event-overlay:before{
    background-color: #ff9287;;
}

.welcome-back .event-overlay:before{
    background-color: #fff5c0;
}

.bg-title .btn{
    margin-top:8px;
}

.pager {
    margin:0px;
}


@media only screen and (max-width:764px) {
    .event-overlay:before{

        width: 96.2%;
        height: 100%;
    }
}


.card-shadow{
    box-shadow: 5px 5px 5px #0000003d;
}

.card-content {
    overflow-y: hidden;
    height: 176px;
}

.card-content:hover{
    overflow:auto;
}

.birthday {
    background-image: url(/assets/images/birthday-card.jpg) !important;
    background-size: 60% !important;
    background-position: center !important;
}

.sweepstakes{
    background-image:url('/assets/images/sweepstakes-card.jpg') !important;
    background-size: cover;
    font-weight: 500;
    background-position: center !important;
    background-size: 60% !important;
}


.welcome-back {
    position: relative;
    font-weight: 500;

}
.welcome-back:before{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/images/welcome-back-card.jpg) !important;
    content: "";
    background-position: center !important;
    background-size: 51% !important;
    background-repeat: no-repeat !important;
    opacity: 0.1;
}
.btn-task{
    height: 34px;
}

.add-note button{
  border: none
}
