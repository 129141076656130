.customSlick {
  padding: 0 10px;
}

.slick-prev:before {
  color: #1abc9c !important;
}
.slick-next:before {
  color: #1abc9c;
}

.slick-list {
    margin: 0 -7px !important;
  }
  
  .slick-slide > div {
    padding: 0 7px !important;
  }
.img-fluid {
  width: 100%
}

.delation {
    position: absolute;
    left: 0;
    z-index: 9999999;
}

.imgBox{
  position: relative
}

.customSlick .owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    font-size: 40px;
    margin-top: -20px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
