


.profile-img {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}


.no-display{
    height:0px;overflow:hidden;
}

form{
    padding-top:30px;
}

.image-caption{
    background: #e1e0e0;
}