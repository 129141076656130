.brand {
    color: #666;
    font-size: 15px;
    font-weight: 700;
    margin-right: 30px;
}

.details-footer{
  margin-top: 30px
}

.product-info .customtab{
  border: none

}

.product-info .customtab.nav-tabs > li > a {
    padding: 8px 15px;
}

.product-info .customtab.nav-tabs > li > a:first-child{
  padding-left: 0
}

.border-bottom{
  width: 100%;
  height: 30px;
  border-bottom: 2px solid #ddd
}

.variant-details span {
    width: 50%;
}

.product-edit-pane {
    position: absolute;
    left: 0;
    width: 70%;
    background: #f2f2f2;
    height: 100%;
    top: 0;
    z-index: 99999999;
  transform: translateX(-100%);
  transition: 400ms linear
}

.product-edit-pane.opened {
  transform: translateX(0);
}

.thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.thumbInner {
    width: 100px;
    padding: 10px;
}

.thumbInner img{
  width: 100%
}
