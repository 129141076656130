.panel-heading{
    padding:8px !important;
}

.p0{
    padding:0;
}

.mh199{
    min-height:199px
}

.panel-footer{
   padding:7px 17px
}

.mt35{
    margin-top:35px;
}

.p5{
    padding:5px;
}

.status-text{
    font-weight:700;
    color:#666
}

.pager{
    cursor: pointer;
}

.hr-border {
    left:0% !important;
}

.pull-left span{
    color: rgb(102, 102, 102);
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
}

.pr0{
    padding-right:0
}

.bl1{
    border-left: 1px solid #b2bcc5;
    padding-left: 5px;
}

.customer-panel-footer{
    text-align: right;
    padding-bottom: 0px!important;
}

.ml10{
    margin-left:10px
}

.bg-title .btn{
    margin-top:8px;
}

.pager {
    margin:0px;
}

.card-shadow{
    box-shadow: 5px 5px 5px #0000003d;
}