.mb8{
    margin-bottom:8px;
}

.p013{
    padding:  0 13px
}

#addServiceModal .modal-footer btn{
    border-radius: 0;margin-right: 4px
}

#addServiceModal .modal-body{
    height:515px
}

.switch{
    margin-top: 12px;
    margin-bottom: 16px;
}

.d-flex{
    display: flex;
}
.search-button{
    height: 36px;
}
.desktop-search .service-search-field{
    border-right: 0px;
}
.desktop-search .service-pager{
    margin-left: 10px;
    display: block;
    margin-top: -2px;
}
.align-items-center{
    align-items: center;
}
